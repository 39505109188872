import React, { useState, useEffect, useRef } from "react";
import throttle from "../lib/throttle";
import BusinessCaseLink from "./BusinessCaseLink";

import { ScrollBackButton, ScrollForwardButton } from "./ScrollButtons";

const throttledScroll = setScrollLeft =>
  throttle(event => {
    setScrollLeft(event.target.scrollLeft);
  }, 100);

export const BusinessCaseCarousel = ({ businessCasesThumbnails, businessCasesPages }) => {
  const [scrollLeft, setScrollLeft] = useState(0);
  const carousel = useRef();

  useEffect(() => {
    if (carousel.current) {
      carousel.current.addEventListener("scroll", throttledScroll(setScrollLeft), {
        passive: true,
      });
    }
  }, []);

  return (
    <div className="fl-grid fl-both-half fl-business-case-carousel-wrapper">
      <ScrollBackButton carousel={carousel} scrollLeft={scrollLeft} />
      <ScrollForwardButton carousel={carousel} scrollLeft={scrollLeft} />
      <div className="fl-business-case-carousel" ref={carousel}>
        {businessCasesPages.map(page => {
          const thumbnail = businessCasesThumbnails.find(
            thNode => thNode.fileAbsolutePath === page.context.mdxFileAbsolutePath
          );

          return <BusinessCaseLink key={page.path} pageNode={page} thumbnail={thumbnail} />;
        })}
      </div>
    </div>
  );
};
