import React, { useState } from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/SEO";

import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";

import driverGuyPosingToTheCamera from "../images/driver-guy-posing-to-the-camera.jpg";
import pullboys from "../images/pullboys.svg";
import fourTrucksWithoutTags from "../images/four-trucks-without-tags.svg";
import writingACmr from "../images/writing-a-cmr.jpg";
import { OpenTruck, Palet, LittleBox, BigBox } from "../components/svg-assets/TruckElements";
import {
  CompensateIcon,
  ExpandIcon,
  OffersIcon,
  PalletExchangeSelected24,
  ReduceIcon,
  TargetIcon,
} from "../components/svg-assets/Icons";
import roadWithTrees from "../images/road-with-trees.jpg";
import depotDoors from "../images/depot-doors.jpg";
import palm from "../images/palm.svg";
import truckLandscape from "../images/truck-landscape.svg";
import { BusinessCaseCarousel } from "../components/BusinessCaseCarousel";
import scenarioTruckSelect from "../images/scenario-truck-select.png";
import scenarioTruckSelectEn from "../images/scenario-truck-select.en.png";
import scenarioOfferExample from "../images/scenario-offer-example.png";
import scenarioOfferExampleEn from "../images/scenario-offer-example.en.png";
import scenarioActiviyDashboard from "../images/scenario-activity-dashboard.png";
import scenarioActiviyDashboardEn from "../images/scenario-activity-dashboard.en.png";

import exampleOffersData from "../../data/exampleOffers.json";

const CarriersPage = ({
  data: {
    businessCases: { nodes: businessCasesPages },
    postThumbnails: { nodes: businessCasesThumbnails },
  },
}) => {
  const { t, i18n } = useTranslation();
  const [technicalScenarioSelected, selectTechnicalScenario] = useState(0);

  const scenarioClassName = scenarioNumber =>
    technicalScenarioSelected === scenarioNumber
      ? "fl-scenario fl-scenario--active"
      : "fl-scenario";

  const ScenarioLink = ({ children, scenarioId }) => (
    <div
      className={scenarioClassName(scenarioId)}
      onClick={() => selectTechnicalScenario(scenarioId)}
      onKeyPress={() => selectTechnicalScenario(scenarioId)}
      role="link"
      tabIndex={0}>
      {children}
    </div>
  );

  const [siret, setSiret] = useState("");
  const [organization, setOrganization] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const startSignUp = event => {
    event.preventDefault();

    window.sessionStorage.setItem("siret", siret);
    window.sessionStorage.setItem("organization", organization);
    window.sessionStorage.setItem("phone", phone);
    window.sessionStorage.setItem("email", email);

    window.location = `/${i18n.language}/sign-up/carrier/`;
  };

  const { exampleOffers } = exampleOffersData;

  return (
    <Layout>
      <SEO title={t("carriers.html-title")} />
      <div className="fl-bg-left fl-bg-pullboys" style={{ top: "0px" }}>
        <img src={pullboys} alt="" />
      </div>
      <div className="fl-grid fl-top-element">
        <div className="fl-first-half fl-green-context">
          <div className="fl-top-block">
            <div className="fl-tag">
              <Trans i18nKey="carriers.headers.tag" />
            </div>
            <h2>
              <Trans i18nKey="carriers.headers.title" />
            </h2>
          </div>
        </div>
        <div className="fl-very-second-half fl-top-block">
          <p className="fl-strong">
            <Trans i18nKey="carriers.headers.p-optimize" />
          </p>
          <Trans parent="p" i18nKey="carriers.headers.p-pilot-your-growth" />
        </div>
      </div>
      <div className="fl-grid" style={{ marginTop: "108px" }}>
        <div className="fl-pan fl-pan--center-linear-gradient fl-grid">
          <img className="fl-pan-bg" src={driverGuyPosingToTheCamera} alt="" />
        </div>
      </div>
      <div className="fl-grid" style={{ marginTop: "104px" }}>
        <div className="fl-first-half fl-green-context">
          <div className="fl-tag fl-naked-tag">
            <Trans i18nKey="carriers.first-block.tag" />
          </div>
          <div className="fl-big-block">
            <h2>
              <Trans i18nKey="carriers.first-block.title" />
            </h2>
            <Trans parent="p" i18nKey="carriers.first-block.p-maintain" />
            <Trans parent="p" i18nKey="carriers.first-block.p-on-time" />
            <Trans parent="p" i18nKey="carriers.first-block.p-with-all-this" />
          </div>
        </div>

        <div className="fl-very-second-half fl-block-right fl-svg-width-wrapper">
          <img src={fourTrucksWithoutTags} alt="" />
        </div>
      </div>
      <div className="fl-grid" style={{ marginTop: "128px" }}>
        <div className="fl-very-first-half" style={{ position: "relative" }}>
          <OpenTruck className="fl-open-truck">
            <Palet x={8} y={2} innerClassName="first-palet">
              {[0, 1, 2, 3].map(z =>
                [2, 1, 0].map(y =>
                  [2, 1, 0].map(x => <LittleBox key={`${x} ${y} ${z}`} x={x} y={y} z={z} />)
                )
              )}
            </Palet>
            <Palet key="7 2" x={7} y={2} innerClassName="first-palet" isCube />
            <Palet key="6 2" x={6} y={2} innerClassName="first-palet" isCube />
            <Palet key="5 2" x={5} y={2} innerClassName="first-palet" isCube />
            <Palet key="4 2" x={4} y={2} innerClassName="first-palet" />
            <Palet key="1 2" x={1} y={2} innerClassName="first-palet" isCube />
            <Palet key="0 2" x={0} y={2} innerClassName="first-palet" isCube />

            <Palet key="8 1" x={8} y={1} innerClassName="second-palet">
              {[0].map(z =>
                [2, 1].map(y =>
                  [2, 1, 0].map(x => <LittleBox key={`${x} ${y} ${z}`} x={x} y={y} z={z} />)
                )
              )}
              <LittleBox key="2 0 0" x={2} y={0} z={0} />
              <LittleBox key="1 0 0" x={1} y={0} z={0} />
              <LittleBox key="0 0 0" x={0} y={0} z={0} color="#288CFF" />
            </Palet>
            <Palet key="7 1" x={7} y={1} innerClassName="second-palet">
              {[0].map(z =>
                [2, 1, 0].map(y =>
                  [2, 1, 0].map(x => <LittleBox key={`${x} ${y} ${z}`} x={x} y={y} z={z} />)
                )
              )}
            </Palet>
            <Palet key="6 1" x={6} y={1} innerClassName="second-palet">
              {[0].map(z =>
                [2, 1, 0].map(y =>
                  [2, 1, 0].map(x => <LittleBox key={`${x} ${y} ${z}`} x={x} y={y} z={z} />)
                )
              )}
              {[1].map(z =>
                [2, 1].map(y =>
                  [2, 1, 0].map(x => <LittleBox key={`${x} ${y} ${z}`} x={x} y={y} z={z} />)
                )
              )}
              <LittleBox key="2 0" x={2} y={0} z={1} />
              <LittleBox key="1 0" x={1} y={0} z={1} color="#288CFF" />
              <LittleBox key="0 0" x={0} y={0} z={1} color="white" />
            </Palet>
            <Palet key="5 1" x={5} y={1} innerClassName="second-palet" />
            <Palet key="4 1" x={4} y={1} innerClassName="second-palet">
              <BigBox key="1 1 0" x={1} y={1} z={0} />
            </Palet>
            <Palet key="1 1" x={1} y={1} innerClassName="second-palet" isCube />
            <Palet key="0 1" x={0} y={1} innerClassName="second-palet" isCube />

            <Palet key="7 0" x={7} y={0} innerClassName="third-palet" />
            <Palet key="6 0" x={6} y={0} innerClassName="third-palet" />
            <Palet key="5 0" x={5} y={0} innerClassName="third-palet" />
            <Palet key="4 0" x={4} y={0} innerClassName="third-palet" />
            <Palet key="1 0" x={1} y={0} innerClassName="third-palet" />
            <Palet key="0 0" x={0} y={0} innerClassName="third-palet" />

            <Palet key="out1" innerClassName="outside-back-palet" transform="translate(384 130)">
              {[0].map(z =>
                [2, 1, 0].map(y =>
                  [2, 1, 0].map(x => <LittleBox key={`${x} ${y} ${z}`} x={x} y={y} z={z} />)
                )
              )}
              {[1].map(z =>
                [2, 1].map(y =>
                  [2, 1, 0].map(x => <LittleBox key={`${x} ${y} ${z}`} x={x} y={y} z={z} />)
                )
              )}
              {[2].map(z =>
                [2].map(y =>
                  [2, 1, 0].map(x => <LittleBox key={`${x} ${y} ${z}`} x={x} y={y} z={z} />)
                )
              )}
              <LittleBox key="2 1 2" x={2} y={1} z={2} />
              <LittleBox key="1 1 2" x={1} y={1} z={2} />
              <LittleBox key="0 1 2" x={0} y={1} z={2} color="white" />
            </Palet>
            <Palet key="out2" innerClassName="outside-front-palet" transform="translate(320 168)">
              <BigBox x={1} y={1} z={0} />
            </Palet>
            <Palet key="out3" innerClassName="outside-front-palet" transform="translate(450 168)" />
          </OpenTruck>
        </div>
        <div className="fl-second-half fl-blue-context">
          <div className="fl-tag fl-naked-tag">
            <Trans i18nKey="carriers.second-block.tag" />
          </div>
          <div className="fl-big-block">
            <h2>
              <Trans i18nKey="carriers.second-block.title" />
            </h2>
            <Trans parent="p" i18nKey="carriers.second-block.p-perpetuate" />
            <Trans parent="p" i18nKey="carriers.second-block.p-we-propose-lots" />
            <Trans parent="p" i18nKey="carriers.second-block.p-exploitation" />
            <Trans parent="p" i18nKey="carriers.second-block.p-our-collaboration" />
          </div>
        </div>
      </div>

      <div className="fl-lighter-blue-wrapper" style={{ marginTop: "208px" }}>
        <div className="fl-bg-right" style={{ marginTop: "80px", zIndex: -1 }}>
          <div
            className="fl-bg-bubble"
            style={{ transform: "rotate(180deg)", marginLeft: "122px" }}
          />
        </div>

        <div className="fl-grid" style={{ marginTop: "104px" }}>
          <div className="fl-medium-block fl-first-half">
            <h2>
              <Trans i18nKey="carriers.business-cases.title" />
            </h2>
            <Trans
              parent="p"
              i18nKey="carriers.business-cases.p-discover-x-testimonies"
              values={{ count: businessCasesPages.length }}
            />
            <p className="fl-link-next">
              <Link to="/business-cases/">
                <Trans i18nKey="carriers.business-cases.see-all" />
              </Link>
            </p>
          </div>
        </div>
        <div className="fl-grid" style={{ marginTop: "40px", marginBottom: "104px" }}>
          <BusinessCaseCarousel
            businessCasesPages={businessCasesPages.reverse()}
            businessCasesThumbnails={businessCasesThumbnails}
          />
        </div>
      </div>
      <div
        className="fl-dot-square-pattern fl-blue-context"
        style={{ paddingTop: "111px", paddingBottom: "104px" }}>
        <div className="fl-grid">
          <div className="fl-medium-block fl-first-half">
            <div className="fl-tag fl-naked-tag">
              <Trans i18nKey="carriers.how-it-works.tag" />
            </div>
            <h2>
              <Trans i18nKey="carriers.how-it-works.title" />
            </h2>
          </div>
        </div>
        <div className="fl-grid" style={{ marginTop: "56px" }}>
          <div className="fl-scenario-list fl-first-third">
            <ScenarioLink scenarioId={0}>
              <code className="fl-scenario-number">
                <Trans i18nKey="carriers.how-it-works.scenarii.first.number" />
              </code>
              <p className="fl-scenario-title">
                <Trans i18nKey="carriers.how-it-works.scenarii.first.title" />
              </p>
              <p className="fl-scenario-description">
                <Trans i18nKey="carriers.how-it-works.scenarii.first.description" />
              </p>
            </ScenarioLink>
            <ScenarioLink scenarioId={1}>
              <code className="fl-scenario-number">
                <Trans i18nKey="carriers.how-it-works.scenarii.second.number" />
              </code>
              <p className="fl-scenario-title">
                <Trans i18nKey="carriers.how-it-works.scenarii.second.title" />
              </p>
              <p className="fl-scenario-description">
                <Trans i18nKey="carriers.how-it-works.scenarii.second.description" />
              </p>
            </ScenarioLink>
            <ScenarioLink scenarioId={2}>
              <code className="fl-scenario-number">
                <Trans i18nKey="carriers.how-it-works.scenarii.third.number" />
              </code>
              <p className="fl-scenario-title">
                <Trans i18nKey="carriers.how-it-works.scenarii.third.title" />
              </p>
              <p className="fl-scenario-description">
                <Trans i18nKey="carriers.how-it-works.scenarii.third.description" />
              </p>
            </ScenarioLink>
          </div>
          {technicalScenarioSelected === 0 ? (
            <div className="fl-second-half">
              <div className="fl-scenario-example-block">
                <h3>
                  <Trans i18nKey="carriers.how-it-works.scenarii.examples.vehicle" />
                </h3>
                <img
                  src={i18n.language === "fr" ? scenarioTruckSelect : scenarioTruckSelectEn}
                  alt={t("carriers.how-it-works.scenarii.examples.vehicle-alt")}
                  title=""
                  width="506"
                  height="136"
                  loading="lazy"
                />
              </div>
              <div className="fl-scenario-example-block">
                <h3>
                  <Trans i18nKey="carriers.how-it-works.scenarii.examples.territory" />
                </h3>
                <ul className="fl-pills">
                  <li className="fl-pill--active">Bourgogne-Franche-Comté</li>
                  <li>Bretagne</li>
                  <li>Auvergne–Rhône-Alpes</li>
                  <li>Corse</li>
                  <li className="fl-pill--active">Hauts-de-France</li>
                  <li>Centre–Val de Loire</li>
                  <li>Grand Est</li>
                  <li>Occitanie</li>
                  <li>Île-de-France</li>
                  <li>Provence-Alpes-Côté d’Azur</li>
                  <li>Nouvelle-Aquitaine</li>
                  <li>Pays de la Loire</li>
                  <li>Normandie</li>
                </ul>
              </div>
            </div>
          ) : undefined}

          {technicalScenarioSelected === 1 ? (
            <div className="fl-second-half">
              <div className="fl-scenario-example-block">
                <img src={scenarioOfferExample} alt="" width="436" height="153" />
              </div>
              <div className="fl-scenario-example-block">
                <img
                  src={i18n.language === "fr" ? scenarioOfferExample : scenarioOfferExampleEn}
                  alt=""
                  width="436"
                  height="153"
                  loading="lazy"
                />
              </div>
            </div>
          ) : undefined}

          {technicalScenarioSelected === 2 ? (
            <div className="fl-second-half">
              <div className="fl-scenario-example-block">
                <h3>
                  <Trans i18nKey="carriers.how-it-works.scenarii.examples.activity-in-numbers" />
                </h3>

                <img
                  src={
                    i18n.language === "fr" ? scenarioActiviyDashboard : scenarioActiviyDashboardEn
                  }
                  alt=""
                  width="506"
                  height="272"
                  loading="lazy"
                />
              </div>
            </div>
          ) : undefined}
        </div>
      </div>

      <div className="fl-grid" style={{ marginTop: "104px" }}>
        <div className="fl-first-half fl-vertical-center">
          <img
            className="fl-single-illustration"
            src={writingACmr}
            height="440"
            alt=""
            loading="lazy"
          />
        </div>
        <div
          className="fl-medium-block fl-blue-context fl-last-third"
          style={{ alignSelf: "center" }}>
          <div className="fl-tag fl-naked-tag">
            <Trans i18nKey="carriers.quick-payment.tag" />
          </div>
          <h2>
            <Trans i18nKey="carriers.quick-payment.title" />
          </h2>
          <Trans parent="p" i18nKey="carriers.quick-payment.p-we-are-paying-each-transport" />
        </div>
      </div>

      {/*
      <div className="fl-grid" style={{ marginTop: "104px" }}>
        <div className="fl-grid fl-blue-block">
          <div className="fl-bg-bubble" style={{ marginLeft: "-150px", marginTop: "104px" }} />
          <div className="fl-first-third fl-green-context">
            <div className="fl-tag fl-naked-tag">
              <Trans i18nKey="carriers.contact-us.tag" />
            </div>
            <div className="fl-big-block">
              <h2>
                <Trans i18nKey="carriers.contact-us.title" />
              </h2>
              <Trans parent="p" i18nKey="carriers.contact-us.p-ask-them-to-call-you-back" />
            </div>

            <div className="fl-person-horizontal" style={{ marginTop: "176px" }}>
              <div className="fl-illustration" />
              <p>TODO : Nom du contact ici</p>
            </div>
          </div>
          <div className="fl-second-half fl-form">
            <label>
              <span>
                <Trans i18nKey="carriers.contact-us.form.department.label" />
              </span>
              <input placeholder={t("carriers.contact-us.form.department.placeholder")} />
            </label>
            <label>
              <span>
                <Trans i18nKey="carriers.contact-us.form.first-name.label" />
              </span>
              <input placeholder={t("carriers.contact-us.form.first-name.placeholder")} />
            </label>
            <label>
              <span>
                <Trans i18nKey="carriers.contact-us.form.phone-number.label" />
              </span>
              <input placeholder={t("carriers.contact-us.form.phone-number.placeholder")} />
            </label>
            <input type="submit" value={t("carriers.contact-us.form.submit.name")} />
          </div>
        </div>
      </div>
      */}

      <div className="fl-lighter-blue-wrapper" style={{ marginTop: "104px", overflow: "hidden" }}>
        <div className="fl-bg-right" style={{ marginTop: "80px", zIndex: -1 }}>
          <div
            className="fl-bg-bubble"
            style={{ transform: "rotate(180deg)", marginLeft: "122px" }}
          />
        </div>
        <div className="fl-grid" style={{ marginTop: "104px" }}>
          <div className="fl-first-third fl-green-context">
            <div className="fl-tag fl-naked-tag">
              <Trans i18nKey="carriers.offers.tag" />
            </div>
            <h2>
              <Trans i18nKey="carriers.offers.title" />
            </h2>
          </div>
        </div>
        <div className="fl-grid" style={{ marginTop: "64px" }}>
          <div className="fl-both-half fl-offers-list">
            {exampleOffers.map((offer, index) => (
              <div className="fl-offer" key={`offer${index}`}>
                <section className="fl-offer-from">
                  <time>
                    {new Date(offer.from.time).toLocaleDateString(i18n.language, {
                      month: "short",
                      day: "numeric",
                    })}
                  </time>
                  <hr />
                  <div className="fl-offer-location">
                    <div className="fl-offer-postal-code">
                      <strong>{offer.from.postalCode.slice(0, 2)}</strong>
                      {offer.from.postalCode.slice(2, 5)}
                    </div>
                    <div className="fl-offer-location-name">{offer.from.city}</div>
                  </div>
                </section>
                <section className="fl-offer-to">
                  <time>
                    {new Date(offer.to.time).toLocaleDateString(i18n.language, {
                      month: "short",
                      day: "numeric",
                    })}
                  </time>
                  <hr />
                  <div className="fl-offer-location">
                    <div className="fl-offer-postal-code">
                      <strong>{offer.to.postalCode.slice(0, 2)}</strong>
                      {offer.to.postalCode.slice(2, 5)}
                    </div>
                    <div className="fl-offer-location-name">{offer.to.city}</div>
                  </div>
                </section>
                <section className="fl-offer-goods">
                  <p>{offer.goods}</p>
                </section>
                <section className="fl-offer-icons">
                  {offer.withPalletExchange ? <PalletExchangeSelected24 /> : undefined}
                </section>
                <section className="fl-offer-specificity">
                  <p>{offer.specificity.main}</p>
                  <p>{offer.specificity.other}</p>
                </section>
              </div>
            ))}
          </div>
        </div>
        <div className="fl-grid" style={{ marginTop: "64px", marginBottom: "64px" }}>
          <p className="fl-second-half fl-link-next fl-link-end">
            <Link to="/sign-up/carrier/">
              <Trans i18nKey="carriers.offers.i-sign-up-to-fretlink" />
            </Link>
          </p>
        </div>
      </div>
      {
        // This is mindfully commented out for the moment.
        // We will re-release the service platform later, for both technical and business reasons.
        /*
      <div className="fl-grid" style={{ marginTop: "104px" }}>
        <div className="fl-first-half fl-medium-block">
          <h2>
            <Trans i18nKey="carriers.exclusive-services.title" />
          </h2>
          <p>
            <Trans i18nKey="carriers.exclusive-services.p-its-a-key-stake" />
          </p>
        </div>
        <div className="fl-last-third" style={{ marginLeft: "112px" }}>
          <UTACard style={{ marginLeft: "auto", marginRight: "25px" }} />
        </div>
      </div>
      <div className="fl-grid fl-services-list" style={{ marginTop: "104px" }}>
        <Link to="/">
          <CardIcon />
          <span>Carte carburant</span>
        </Link>
        <Link to="/">
          <TrucksIcon />
          <span>Achat d’occasions poids lourds</span>
        </Link>
        <Link to="/">
          <KeyHandIcon />
          <span>Location de semi-remorques</span>
        </Link>
        <Link to="/">
          <HelpIcon />
          <span>Assistance dépannage 24h/24 7j/7</span>
        </Link>
        <Link to="/">
          <PliersIcon />
          <span>Entretien mécanique et carrosserie</span>
        </Link>
        <Link className="fl-see-all" to="/">
          <span>Voir tous les services</span>
        </Link>
      </div>
      */
      }

      {/* Green roads */}
      <div
        className="fl-bg-left"
        style={{ marginTop: "-450px", marginRight: "-80px", width: "calc(50% + 80px)" }}>
        <div className="fl-hider-gradient" />
        <img src={palm} alt="" loading="lazy" />
      </div>
      <div className="fl-grid" style={{ marginTop: "120px" }}>
        <div className="fl-pan fl-pan--left-soft-gradient fl-green-context fl-grid">
          <img className="fl-pan-bg" src={roadWithTrees} alt="" loading="lazy" />
          <div className="fl-first-third fl-vertical-center">
            <div className="fl-tag">
              <Trans i18nKey="carriers.green-roads.tag" />
            </div>
            <h2>
              <Trans i18nKey="carriers.green-roads.title" />
            </h2>
          </div>
        </div>
      </div>
      <div className="fl-grid" style={{ marginTop: "72px" }}>
        <div className="fl-first-third">
          <h2>
            <Trans i18nKey="carriers.green-roads.do-you-want-to-evolve" />
          </h2>
        </div>
        <div className="fl-second-half fl-medium-block fl-green-context">
          <Trans parent="p" i18nKey="carriers.green-roads.p-a-specific-offer" />
          <p className="fl-link-next">
            <Link to="/green-roads/">
              <Trans i18nKey="carriers.green-roads.discover-green-road-approach" />
            </Link>
          </p>
        </div>
      </div>
      <div
        className="fl-grid fl-three-cards fl-green-context"
        style={{ marginTop: "72px", marginBottom: "104px" }}>
        <section>
          <ExpandIcon />
          <div className="fl-tag fl-naked-tag">
            <Trans i18nKey="carriers.green-roads.three-cards.first.tag" />
          </div>
          <h3>
            <code>
              <Trans i18nKey="carriers.green-roads.three-cards.first.number" />
            </code>
            <Trans i18nKey="carriers.green-roads.three-cards.first.title" />
          </h3>
          <ul>
            <Trans parent="li" i18nKey="carriers.green-roads.three-cards.first.content.0" />
            <Trans parent="li" i18nKey="carriers.green-roads.three-cards.first.content.1" />
            <Trans parent="li" i18nKey="carriers.green-roads.three-cards.first.content.2" />
            <Trans parent="li" i18nKey="carriers.green-roads.three-cards.first.content.3" />
          </ul>
        </section>
        <section>
          <ReduceIcon />
          <div className="fl-tag fl-naked-tag">
            <Trans i18nKey="carriers.green-roads.three-cards.second.tag" />
          </div>
          <h3>
            <code>
              <Trans i18nKey="carriers.green-roads.three-cards.second.number" />
            </code>
            <Trans i18nKey="carriers.green-roads.three-cards.second.title" />
          </h3>
          <ul>
            <Trans parent="li" i18nKey="carriers.green-roads.three-cards.second.content.0" />
            <Trans parent="li" i18nKey="carriers.green-roads.three-cards.second.content.1" />
            <Trans parent="li" i18nKey="carriers.green-roads.three-cards.second.content.2" />
            <Trans parent="li" i18nKey="carriers.green-roads.three-cards.second.content.3" />
          </ul>
        </section>
        <section>
          <CompensateIcon />
          <div className="fl-tag fl-naked-tag">
            <Trans i18nKey="carriers.green-roads.three-cards.third.tag" />
          </div>
          <h3>
            <code>
              <Trans i18nKey="carriers.green-roads.three-cards.third.number" />
            </code>
            <Trans i18nKey="carriers.green-roads.three-cards.third.title" />
          </h3>
          <ul>
            <Trans parent="li" i18nKey="carriers.green-roads.three-cards.third.content.0" />
            <Trans parent="li" i18nKey="carriers.green-roads.three-cards.third.content.1" />
            <Trans parent="li" i18nKey="carriers.green-roads.three-cards.third.content.2" />
          </ul>
        </section>
      </div>
      <div className="fl-grid">
        <div className="fl-first-third fl-medium-block">
          <h2>
            <Trans i18nKey="carriers.sign-up.title" />
          </h2>
          <Trans parent="p" i18nKey="carriers.sign-up.p-fill-some-data" />
        </div>
        <div className="fl-second-half" style={{ position: "relative", zIndex: 1 }}>
          <form
            className="fl-form fl-resp-md-lg-absolute"
            style={{ width: "100%" }}
            onSubmit={startSignUp}>
            <label>
              <span>
                <Trans i18nKey="carriers.sign-up.form.company-name.label" />
              </span>
              <input
                placeholder={t("carriers.sign-up.form.company-name.placeholder")}
                value={organization}
                onChange={event => setOrganization(event.target.value)}
              />
            </label>
            <label>
              <span>
                <Trans i18nKey="carriers.sign-up.form.siret.label" />
              </span>
              <input
                placeholder={t("carriers.sign-up.form.siret.placeholder")}
                value={siret}
                onChange={event => setSiret(event.target.value)}
              />
            </label>
            <label>
              <span>
                <Trans i18nKey="carriers.sign-up.form.phone-number.label" />
              </span>
              <input
                placeholder={t("carriers.sign-up.form.phone-number.placeholder")}
                value={phone}
                onChange={event => setPhone(event.target.value)}
              />
            </label>
            <label>
              <span>
                <Trans i18nKey="carriers.sign-up.form.e-mail.label" />
              </span>
              <input
                placeholder={t("carriers.sign-up.form.e-mail.placeholder")}
                value={email}
                onChange={event => setEmail(event.target.value)}
              />
            </label>
            <input type="submit" value={t("carriers.sign-up.form.submit.name")} />
          </form>
        </div>
      </div>

      <div className="fl-grid" style={{ marginTop: "80px" }}>
        <div className="fl-pan fl-pan--left-gradient fl-grid">
          <img className="fl-pan-bg" src={depotDoors} alt="" loading="lazy" />
        </div>
      </div>

      <div className="fl-grid" style={{ marginTop: "104px" }}>
        <div className="fl-first-half fl-medium-block">
          <h2>
            <Trans i18nKey="carriers.why-data.title" />
          </h2>
          <Trans parent="p" i18nKey="carriers.why-data.p-your-time-is-precious" />
          <Trans parent="p" i18nKey="carriers.why-data.p-better-we-understand-your-fleet" />
          <p className="fl-link-next">
            <Link to="/transport-operating-system/">
              <Trans i18nKey="carriers.why-data.learn-more" />
            </Link>
          </p>
        </div>
        <div className="fl-last-third fl-sheet">
          <div className="fl-tag fl-naked-tag">
            <Trans i18nKey="carriers.why-data.a-simple-tool.tag" />
          </div>
          <div className="fl-icon-group">
            <TargetIcon />
            <h3>
              <Trans i18nKey="carriers.why-data.a-simple-tool.first.title" />
            </h3>
            <Trans parent="p" i18nKey="carriers.why-data.a-simple-tool.first.content" />
          </div>
          <div className="fl-icon-group">
            <OffersIcon />
            <h3>
              <Trans i18nKey="carriers.why-data.a-simple-tool.second.title" />
            </h3>
            <Trans parent="p" i18nKey="carriers.why-data.a-simple-tool.second.content" />
          </div>
        </div>
      </div>
      <div className="fl-grid" style={{ marginTop: "104px" }}>
        <div className="fl-both-half">
          <img src={truckLandscape} loading="lazy" alt="" />
        </div>
      </div>
      <div className="fl-grid" style={{ marginTop: "104px" }}>
        <div className="fl-medium-block fl-both-half">
          <h2>
            <Trans i18nKey="carriers.your-independance-your-trust.title" />
          </h2>
          <Trans
            parent="p"
            i18nKey="carriers.your-independance-your-trust.p-before-all-work-with-us"
          />
        </div>
      </div>
      <div className="fl-grid" style={{ marginTop: "80px", marginBottom: "80px" }}>
        <div className="fl-step-block fl-first-half">
          <code>
            <Trans i18nKey="carriers.your-independance-your-trust.two-facts.first.number" />
          </code>
          <h3>
            <Trans i18nKey="carriers.your-independance-your-trust.two-facts.first.title" />
          </h3>
          <Trans
            parent="p"
            i18nKey="carriers.your-independance-your-trust.two-facts.first.content"
          />
        </div>
        <div className="fl-step-block fl-second-half">
          <code>
            <Trans i18nKey="carriers.your-independance-your-trust.two-facts.second.number" />
          </code>
          <h3>
            <Trans i18nKey="carriers.your-independance-your-trust.two-facts.second.title" />
          </h3>
          <Trans
            parent="p"
            i18nKey="carriers.your-independance-your-trust.two-facts.second.content"
          />
        </div>
      </div>
    </Layout>
  );
};

export default CarriersPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    businessCases: allSitePage(
      filter: { path: { regex: "/business-cases/.+/" }, context: { language: { eq: $language } } }
    ) {
      nodes {
        path
        context {
          frontmatter {
            catchPhrase
            contactName
            companyName
          }
          mdxFileAbsolutePath
        }
      }
    }
    postThumbnails: allMdx(filter: { fileAbsolutePath: { regex: "/business-cases/.+/" } }) {
      nodes {
        fileAbsolutePath
        frontmatter {
          postThumbnail {
            publicURL
          }
        }
      }
    }
  }
`;
