import React, { Component } from "react";
import PropTypes from "prop-types";

export class OpenTruck extends Component {
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    let { children, className, ...other } = this.props;

    return (
      <svg
        className={`truck-for-services ${className ?? ""}`}
        width="925"
        height="821"
        viewBox="0 0 925 821"
        xmlns="http://www.w3.org/2000/svg"
        {...other}>
        <defs>
          {/* For reference, the "true" side truck path is M357.24 447.7v800.82l658.7-22V68.45z.
           * Here, we "cheat", to let palets appear as if they were moved inside the truck from outside. */}
          <path id="open-truck-path" d="M357.24 447.7v800.82l658.7-22V68.45z" />
        </defs>

        <g fill="none" fillRule="evenodd">
          <g strokeWidth=".89" stroke="#6482A4">
            <path d="M733.05 231.1l182.89-105.64v219.38l-182.9-105.4zM671.52 331.49c-23.11 13.92-39.98 43.33-40.48 70.27-.03 1.41.02 2.77.13 4.1l80.8-49.37.05-1.42c.46-23.82-19.92-35.97-40.5-23.58z" />
            <path d="M697.52 328.6l-27.84-15.76c-7.6-4.47-17.84-4.4-28.13 1.79-23.11 13.93-39.98 43.34-40.49 70.27-.02 1.2.03 2.36.1 3.5l30 17.29c-.1-1.27-.15-2.58-.12-3.93.5-26.93 17.37-56.35 40.49-70.27 9.47-5.7 18.9-6.2 26.27-2.75l-.28-.14zM443.9 478.26l-27.84-15.76c-7.6-4.47-17.84-4.41-28.12 1.79-23.12 13.93-39.98 43.33-40.5 70.27-.02 1.2.03 2.36.11 3.5l30 17.3c-.11-1.28-.15-2.58-.13-3.93.5-26.94 17.38-56.35 40.5-70.28 9.46-5.7 18.88-6.2 26.26-2.75l-.28-.14z" />
            <path d="M371.1 560.72l-56.76 32.72v-29.99l56.76-32.72z" />
            <path d="M286 547.14v29.99l28.38 16.36V563.5z" />
            <path d="M286 547.14l56.72-32.78 28.38 16.37-56.72 32.77zM517.85 460.15v-.01l-.22-.35.22.36zM436.98 509.13v-.02l-.22-.35.22.37z" />
            <path
              fill="#001428"
              d="M898.57 405.23l7.71-2.85V379.9l-7.7 2.84zM898.57 405.23l-164.03-95.37v-22.49l164.03 95.36z"
            />
            <path fill="#001428" d="M906.28 379.82l-164.02-95.36-7.71 2.91 164.02 95.36z" />
            <path d="M833 352.11L257.09 683.88v11.93l575.93-331.78zM762 311.18L186.07 642.95l71 40.93L833 352.11z" />
            <path fill="#001428" d="M485.4 625.22l-56.76 32.73v-30l56.76-32.72z" />
            <path fill="#001428" d="M400.3 611.65v29.99L428.68 658V628z" />
            <path d="M400.3 611.65l56.72-32.77 28.38 16.35-56.72 32.78z" />
            <path fill="#001428" d="M643.13 532.11l-56.76 32.73v-30l56.76-32.71z" />
            <path fill="#001428" d="M558.03 518.54v29.99l28.38 16.36V534.9z" />
            <path d="M558.03 518.54l56.71-32.77 28.39 16.36-56.72 32.77z" />
            <path fill="#001428" d="M857.2 406.23l-56.76 32.73v-29.99l56.76-32.72z" />
            <path d="M772.1 392.66v30L800.48 439v-29.99z" />
            <path d="M772.1 392.66l56.71-32.77 28.39 16.36-56.72 32.77z" />
            <path fill="#001428" d="M403.84 680.04l-94.33 54.38v-49.83l94.33-54.39z" />
            <path
              fill="#001428"
              d="M262.42 657.48l94.26-54.46 47.15 27.18-94.24 54.48zM262.42 657.48v49.83l47.17 27.2v-49.83z"
            />
            <path d="M792.65 400.1a65.2 65.2 0 00-6.54 4.62l43.45 17.07c.4-21.9-18.17-33.08-36.91-21.68z" />
            <path
              d="M814.73 401.74l-23.38-13.23c-6.4-3.75-14.98-3.7-23.62 1.5-19.42 11.7-33.58 36.4-34.01 59.02-.2 10.5 4.4 18.28 11.32 21.83l26.13 14.62c6.14 2.72 13.91 2.23 21.73-2.47 19.41-11.7 33.58-36.43 34-59.03.22-10.92-4.78-18.91-12.17-22.24z"
              fill="#001428"
            />
            <path
              d="M826.9 423.97c-.42 22.61-14.58 47.34-34 59.04-17.28 10.4-34.39.2-34-19.81.41-22.63 14.59-47.33 34-59.03 17.28-10.4 34.38-.2 34 19.8z"
              fill="#001428"
            />
            <path
              d="M824.55 426.6c-.4 20.8-13.3 43.56-31 54.33-15.77 9.58-31.36.19-31.01-18.23.38-20.82 13.3-43.56 31-54.32 15.75-9.58 31.35-.18 31 18.22z"
              fill="#001428"
            />
            <path d="M815.96 432.27c-.27 14.23-9.19 29.8-21.4 37.16-10.89 6.55-21.65.14-21.42-12.47.27-14.24 9.18-29.79 21.41-37.15 10.88-6.56 21.65-.13 21.4 12.46z" />
            <path d="M801.83 417.27c6.22.65 10.87 6 10.72 14.09-.25 13.37-8.6 27.99-20.05 34.9-8.2 4.96-16.34 2-19.07-5.52 1.76 10.03 11.4 14.55 21.12 8.7 12.22-7.37 21.14-22.94 21.41-37.17.19-9.72-6.2-15.77-14.13-15z" />
            <path d="M807.15 438.6c-.14 7.58-4.89 15.87-11.4 19.79-5.8 3.49-11.52.07-11.4-6.64.15-7.59 4.9-15.87 11.4-19.78 5.8-3.5 11.53-.07 11.4 6.64zM810.95 423.07v-.01l-.21-.35.21.36zM787.5 437.19l-5.2-5.14a48.36 48.36 0 00-2.63 4.15l6.53 3.01c.4-.69.84-1.36 1.3-2.02zM795.29 429.67c.33-.2.66-.37.99-.52l-1.35-9.04c-.16.09-.3.15-.46.25-1.28.78-2.5 1.68-3.7 2.63l3.2 7.54c.43-.3.86-.59 1.32-.86zM784.7 442.18l-6.75-2.6a45.44 45.44 0 00-2 4.95l7.66.54c.31-.97.68-1.94 1.09-2.89zM791.27 432.75l-3.07-7.54a44.06 44.06 0 00-3.53 3.77l4.61 5.88c.64-.74 1.3-1.45 1.99-2.1zM806.26 430.92l3.92-6.56a13.02 13.02 0 00-1.9-2.8l-3.08 8.23c.4.33.74.72 1.06 1.13zM807.94 434.66l4.12-4.74c-.2-.99-.43-1.93-.73-2.82l-4.12 5.36c.32.67.57 1.4.73 2.2zM803.71 428.84l2.6-9.05a8.84 8.84 0 00-3.08-1.37l-1.26 9.84c.61.12 1.2.32 1.74.58zM800.2 428.11l1.12-9.91h-.07c-1.21 0-2.47.25-3.74.7l.92 9.47c.6-.15 1.2-.23 1.78-.26zM782.81 448.3h-7.93c-.4 1.65-.68 3.3-.86 4.93l8.45-2.07c.06-.95.17-1.9.34-2.86zM798.49 457.14l-.26 4.8c.9-.83 1.76-1.69 2.58-2.6l-.68-3.76c-.52.55-1.08 1.07-1.64 1.56z" />
            <path d="M802.71 452.5l1.17 3.11c1.03-1.41 2-2.9 2.87-4.42l-2.78-.58c-.4.65-.82 1.28-1.26 1.9zM805.77 447.2l2.68.68c.8-1.7 1.5-3.45 2.08-5.23l-3.47 1.16a27 27 0 01-1.3 3.4zM782.58 454.09l-8.76 2.24-.02.63c0 1.2.17 2.34.5 3.39l8.83-4.2a9.91 9.91 0 01-.55-2.06zM808.15 437.3c-.03 1.15-.16 2.3-.36 3.46l3.7-1.45c.46-1.91.78-3.83.93-5.74l-4.27 3.74zM795.29 459.5c-.4.23-.8.44-1.2.62l-2.65 6.6.63-.22.43-.23c.93-.56 1.82-1.18 2.7-1.84l1.18-5.65c-.37.25-.72.5-1.1.71zM783.92 457.7l-8.44 5.07a9.6 9.6 0 002.8 2.7l6.7-6.47c-.4-.39-.75-.82-1.06-1.3zM786.53 460.15l-6.24 6.35c.91.36 1.9.63 2.94.8l4.34-6.69c-.36-.12-.7-.28-1.04-.46zM789.28 461.02l-3.5 6.5.17.01c.81 0 1.65-.07 2.48-.18l3-6.4c-.74.1-1.46.14-2.15.07zM755.76 466.89a34.4 34.4 0 01-.03-2.1c.47-24.8 15.84-51.86 36.92-64.68 18.74-11.4 37.32-.23 36.9 21.68l2.78-1.69c0-.47.04-.95.05-1.42.45-23.82-19.92-35.98-40.49-23.58-23.12 13.93-39.98 43.34-40.5 70.28-.02 1.4.03 2.76.15 4.09l4.22-2.58z" />
            <path d="M817.88 392.2l-27.84-15.75c-7.6-4.47-17.84-4.4-28.12 1.79-23.13 13.92-40 43.34-40.5 70.28-.02 1.2.03 2.35.1 3.49l30 17.29c-.1-1.27-.15-2.58-.13-3.92.52-26.94 17.38-56.35 40.5-70.28 9.47-5.7 18.89-6.2 26.26-2.75l-.27-.15z" />
            <path
              d="M817.88 392.2l-27.84-15.75c-7.6-4.47-17.84-4.4-28.12 1.79-23.13 13.92-40 43.34-40.5 70.28-.02 1.2.03 2.35.1 3.49l30 17.29c-.1-1.27-.15-2.58-.13-3.92.52-26.94 17.38-56.35 40.5-70.28 9.47-5.7 18.89-6.2 26.26-2.75l-.27-.15z"
              fill="#001428"
            />
            <path d="M711.77 449.07a64.44 64.44 0 00-6.53 4.62l43.45 17.07c.4-21.9-18.17-33.08-36.92-21.69z" />
            <path
              d="M733.86 448.87l-23.38-13.24c-6.4-3.75-15-3.7-23.63 1.5-19.42 11.7-33.58 36.4-34 59.03-.2 10.5 4.4 18.28 11.3 21.82l26.14 14.63c6.14 2.71 13.92 2.22 21.74-2.49 19.4-11.69 33.58-36.42 34-59.02.21-10.91-4.79-18.9-12.17-22.23z"
              fill="#001428"
            />
            <path
              d="M746.04 471.1c-.43 22.6-14.6 47.33-34.01 59.02-17.29 10.42-34.39.22-34-19.8.42-22.63 14.58-47.33 34-59.03 17.27-10.4 34.38-.2 34 19.8z"
              fill="#001428"
            />
            <path
              d="M743.67 475.57c-.39 20.8-13.3 43.57-31 54.32-15.76 9.58-31.35.2-31-18.22.38-20.83 13.3-43.56 31-54.32 15.74-9.58 31.35-.19 31 18.22z"
              fill="#001428"
            />
            <path d="M735.09 479.4c-.27 14.22-9.19 29.8-21.42 37.16-10.87 6.55-21.64.13-21.4-12.48.27-14.24 9.18-29.79 21.4-37.16 10.89-6.54 21.66-.12 21.42 12.48z" />
            <path d="M720.96 464.4c6.21.64 10.87 6 10.72 14.08-.25 13.37-8.6 27.99-20.05 34.9-8.2 4.97-16.34 2-19.07-5.52 1.75 10.03 11.38 14.56 21.11 8.7 12.23-7.37 21.15-22.94 21.42-37.16.18-9.73-6.2-15.77-14.13-15z" />
            <path d="M726.28 485.73c-.14 7.57-4.89 15.87-11.4 19.78-5.8 3.49-11.52.07-11.4-6.64.15-7.58 4.9-15.86 11.4-19.78 5.8-3.5 11.52-.07 11.4 6.64zM730.08 472.05v-.03l-.22-.34.22.37zM706.63 484.31l-5.2-5.13a47.2 47.2 0 00-2.64 4.14l6.54 3.02c.4-.7.84-1.37 1.3-2.03zM714.41 476.8c.34-.21.67-.37.99-.53l-1.34-9.04c-.16.1-.31.16-.47.25-1.27.79-2.5 1.68-3.7 2.63l3.2 7.55c.44-.31.87-.6 1.32-.87zM703.83 489.3l-6.76-2.6a46.94 46.94 0 00-1.99 4.96l7.66.53c.31-.97.67-1.94 1.09-2.89zM710.4 479.88l-3.08-7.55a44.62 44.62 0 00-3.53 3.78l4.62 5.87c.63-.74 1.3-1.44 1.99-2.1zM725.4 478.04l3.9-6.55a14.37 14.37 0 00-1.9-2.81l-3.08 8.24c.4.33.75.7 1.07 1.12zM727.07 481.79l4.12-4.75c-.2-.99-.43-1.93-.73-2.82l-4.12 5.36c.32.67.56 1.4.73 2.2zM722.83 475.97l2.6-9.06a8.73 8.73 0 00-3.07-1.36l-1.27 9.83c.62.13 1.2.32 1.74.59zM719.34 475.23l1.1-9.91h-.06c-1.22 0-2.47.25-3.75.7l.93 9.47a8.8 8.8 0 011.78-.26zM701.94 495.43H694c-.4 1.64-.68 3.29-.86 4.93l8.45-2.08c.05-.94.16-1.9.34-2.85zM717.61 504.26l-.25 4.8a39.5 39.5 0 002.58-2.6l-.68-3.76c-.53.55-1.08 1.07-1.65 1.56z" />
            <path d="M721.83 499.63l1.17 3.1c1.03-1.4 2-2.89 2.87-4.42l-2.78-.58c-.4.65-.82 1.29-1.26 1.9zM724.9 494.33l2.67.67c.8-1.7 1.5-3.45 2.08-5.22l-3.47 1.15a27.7 27.7 0 01-1.28 3.4zM701.7 501.21l-8.75 2.24-.03.63c0 1.21.18 2.34.5 3.39l8.83-4.2a9.68 9.68 0 01-.55-2.06zM727.27 484.43c-.03 1.15-.15 2.3-.36 3.45l3.7-1.45c.46-1.91.78-3.83.94-5.74l-4.28 3.74zM714.41 506.61c-.4.25-.79.45-1.18.64l-2.68 6.6.65-.23c.14-.08.29-.14.43-.23.93-.56 1.82-1.18 2.7-1.84l1.18-5.64c-.37.24-.73.48-1.1.7zM703.04 504.82l-8.43 5.06a9.16 9.16 0 002.8 2.71l6.7-6.47c-.4-.39-.75-.81-1.07-1.3zM705.65 507.28l-6.24 6.34c.92.37 1.9.64 2.95.8l4.34-6.68c-.37-.13-.7-.28-1.05-.46zM708.41 508.13l-3.5 6.51.16.01c.82 0 1.65-.06 2.49-.18l3-6.4c-.74.1-1.46.13-2.15.06z" />
            <path
              d="M674.88 515.86c-.02-.7-.03-1.4-.02-2.11.46-24.79 15.84-51.85 36.91-64.68 18.75-11.4 37.32-.22 36.91 21.69l2.77-1.69.05-1.42c.45-23.82-19.92-35.98-40.49-23.59-23.12 13.94-39.98 43.35-40.49 70.28-.02 1.41.03 2.77.15 4.1l4.21-2.58z"
              fill="#001428"
            />
            <path d="M737 441.18l-27.83-15.77c-7.6-4.46-17.84-4.4-28.13 1.8-23.12 13.92-39.98 43.33-40.49 70.27-.02 1.2.02 2.36.1 3.5l30 17.29c-.1-1.27-.15-2.57-.13-3.93.5-26.94 17.37-56.34 40.5-70.28 9.47-5.7 18.88-6.19 26.26-2.74l-.27-.14z" />
            <path
              d="M737 441.18l-27.83-15.77c-7.6-4.46-17.84-4.4-28.13 1.8-23.12 13.92-39.98 43.33-40.49 70.27-.02 1.2.02 2.36.1 3.5l30 17.29c-.1-1.27-.15-2.57-.13-3.93.5-26.94 17.37-56.34 40.5-70.28 9.47-5.7 18.88-6.19 26.26-2.74l-.27-.14zM349.87 443.45l-194.6-112.88v236.36l194.6 112.88z"
              fill="#001428"
            />
            <path fill="#001428" d="M923.3 349.32V112.96L349.87 443.45V679.8z" />
            <path d="M733.05 239.43L357.24 456.08v-8.39l375.8-216.6zM733.05 239.43l182.89 105.64-558.7 322V456.09z" />
            <path fill="#001428" d="M923.3 112.96L728.7.08 155.27 330.57l194.6 112.88z" />
            <path d="M18.94 577.94v4.38l7.44 6.31v-6.4zM15.79 560.44l-7.22-4.16c-1.73-1-3.14-.19-3.14 1.8v24.23c0 2 1.4 4.45 3.14 5.44l7.22 4.16c1.73 1 3.15.19 3.15-1.82v-24.21c0-2-1.42-4.44-3.15-5.44z" />
            <path d="M15.79 560.44l-7.22-4.16c-1.61-.94-2.93-.27-3.1 1.43.38.03.8.16 1.25.42l7.21 4.16a6.97 6.97 0 013.15 5.45v24.21c0 .14-.03.26-.04.38 1.12.08 1.9-.75 1.9-2.24v-24.21c0-2-1.42-4.44-3.15-5.44z" />
            <path
              d="M265.57 702.45l.07-.24-28.81 16.61 51.04 29.68c.59-3.88.9-7.77.9-11.6 0-20.86-7.52-35.83-23.2-34.45z"
              fill="#001428"
            />
            <path
              d="M270.85 715.45l-23.4-13.24c-6.38-3.75-14.97-3.7-23.6 1.5-19.43 11.7-33.6 36.4-34.02 59.03-.2 10.49 4.4 18.28 11.32 21.82l26.13 14.63c6.14 2.72 13.92 2.22 21.74-2.49 19.4-11.69 33.58-36.42 34-59.02.2-10.91-4.79-18.91-12.17-22.23z"
              fill="#001428"
            />
            <path
              d="M283.02 737.68c-.42 22.6-14.59 47.33-34 59.02-17.29 10.42-34.39.22-34.01-19.8.42-22.62 14.59-47.33 34.01-59.03 17.27-10.4 34.38-.2 34 19.8z"
              fill="#001428"
            />
            <path
              d="M280.65 740.3c-.38 20.8-13.3 43.57-31 54.33-15.76 9.58-31.34.19-31-18.23.39-20.82 13.3-43.55 31-54.32 15.76-9.58 31.36-.18 31 18.22z"
              fill="#001428"
            />
            <path d="M272.08 745.97c-.27 14.24-9.2 29.8-21.42 37.17-10.88 6.54-21.64.13-21.4-12.47.26-14.25 9.18-29.8 21.4-37.17 10.88-6.54 21.65-.12 21.42 12.47z" />
            <path d="M257.95 730.98c6.2.64 10.86 6 10.71 14.08-.25 13.37-8.6 28-20.04 34.9-8.22 4.97-16.34 2-19.08-5.52 1.76 10.03 11.4 14.55 21.12 8.7 12.22-7.37 21.14-22.93 21.42-37.17.18-9.72-6.2-15.76-14.13-15z" />
            <path d="M263.26 752.3c-.13 7.58-4.89 15.87-11.4 19.79-5.78 3.49-11.52.07-11.4-6.64.15-7.58 4.9-15.86 11.4-19.78 5.8-3.5 11.53-.07 11.4 6.64zM267.07 736.77h0l-.22-.36c.07.12.14.25.22.36zM243.61 750.9l-5.2-5.14a49.5 49.5 0 00-2.63 4.14l6.53 3.02c.4-.7.85-1.37 1.3-2.03zM251.4 743.37c.33-.2.66-.37.99-.52l-1.35-9.04-.46.26c-1.28.78-2.5 1.67-3.7 2.62l3.2 7.55c.44-.31.87-.6 1.32-.87zM240.81 755.88l-6.75-2.6a45.5 45.5 0 00-2 4.96l7.67.53c.3-.97.67-1.93 1.08-2.89zM247.39 746.45l-3.08-7.54a44.42 44.42 0 00-3.53 3.77l4.62 5.88c.63-.74 1.3-1.45 1.99-2.1zM262.38 744.62l3.91-6.55a12.92 12.92 0 00-1.9-2.8l-3.08 8.23c.4.32.75.7 1.07 1.12zM264.05 748.37l4.13-4.75c-.2-.99-.44-1.93-.74-2.82l-4.11 5.36c.32.67.56 1.4.72 2.2zM259.82 742.54l2.6-9.05a8.87 8.87 0 00-3.08-1.36l-1.26 9.84c.62.11 1.2.32 1.74.57zM256.32 741.82l1.1-9.93h-.06c-1.21 0-2.46.26-3.74.71l.92 9.47c.6-.15 1.2-.23 1.78-.25zM238.92 762H231c-.4 1.65-.68 3.3-.85 4.93l8.44-2.07c.06-.95.16-1.9.34-2.85zM254.6 770.84l-.26 4.8c.9-.83 1.76-1.7 2.58-2.6l-.68-3.76c-.53.56-1.08 1.07-1.64 1.56z" />
            <path d="M258.82 766.2l1.16 3.11a46.2 46.2 0 002.88-4.42l-2.78-.57c-.4.64-.82 1.27-1.26 1.88zM261.88 760.9l2.68.69c.8-1.71 1.5-3.46 2.08-5.23l-3.47 1.15a28.67 28.67 0 01-1.29 3.4zM238.7 767.79l-8.77 2.24-.02.64c0 1.2.17 2.34.5 3.38l8.82-4.2c-.24-.64-.42-1.33-.54-2.06zM264.26 751.01c-.03 1.14-.15 2.3-.36 3.45l3.7-1.45c.46-1.92.78-3.83.94-5.75l-4.28 3.75zM251.4 773.2c-.4.24-.8.45-1.19.63l-2.67 6.6.65-.23.43-.23c.92-.56 1.81-1.19 2.7-1.83l1.17-5.65c-.36.24-.72.49-1.09.7zM240.03 771.4l-8.43 5.07a9.37 9.37 0 002.8 2.7l6.7-6.47c-.4-.38-.76-.8-1.07-1.3zM242.64 773.86l-6.24 6.34c.92.36 1.9.63 2.95.81l4.33-6.69a7.2 7.2 0 01-1.04-.46zM245.4 774.71l-3.5 6.51.15.01c.83 0 1.66-.07 2.5-.18l3-6.4a8.3 8.3 0 01-2.16.06z" />
            <path
              d="M268.37 692.6l-2.8 9.85c15.68-1.38 23.2 13.58 23.2 34.44 0 3.84-.31 7.73-.9 11.61l6.87-3.96c.51-3.9.78-7.78.78-11.64 0-24.4-8.8-41.91-27.15-40.3z"
              fill="#001428"
            />
            <path
              d="M298.08 544.36c-.2-9-5.6-11.57-5.6-11.57l-152.76-88.5-84.78 48.89s-18.7 11.2-22.7 28.77L5.53 609.2v82.85l-1.27.73a8.48 8.48 0 00-3.83 6.62v27.17c0 1.23.44 2.08 1.15 2.48h0l147.94 85.62c1.35.75 6.84 3.7 11.1 3.6 4.87-.1 9.82-2.27 9.82-2.27l38.8-22.36v-34.86a105.02 105.02 0 0122.11-36.8l66.72-38.46V544.36z"
              fill="#001428"
            />
            <path
              d="M164.05 817.89c3.53-.65 6.4-1.9 6.4-1.9l38.8-22.36v-34.86a104.99 104.99 0 0122.11-36.8l66.73-38.46V544.36a16.65 16.65 0 00-1.65-7.18l-82.98 47.85s-18.7 11.2-22.72 28.76l-1.13 5.71"
              fill="#001428"
            />
            <path d="M184.92 684.33c-1 5.29 1.59 6.95 5.73 3.68l46.26-36.8c4.14-3.25 7.52-9.9 7.52-14.75v-35.5c0-4.86-3.43-6.85-7.65-4.44l-28.62 16.13c-4.21 2.41-8.46 8.7-9.45 14l-13.8 57.68z" />
            <path d="M240.17 597.83c-.64 0-1.4.25-2.22.71l-28.64 16.14c-3.63 2.07-7.43 7.75-8.3 12.4v.06l-.02.05-13.78 57.62a5.61 5.61 0 00-.06 2.51 5.55 5.55 0 002.04-1.14l46.26-36.79c3.6-2.84 6.65-8.76 6.65-12.93v-35.5c0-1.17-.25-3.13-1.93-3.13zM256.04 624.85V595.4c0-4.86 3.45-10.81 7.65-13.23l21.13-12.19c2.6-1.5 4.9-1.31 6.28.18-1.22-2.29-3.86-2.85-6.92-1.1l-21.13 12.2c-4.2 2.42-7.65 8.37-7.65 13.22v29.46c0 1.86.5 3.3 1.37 4.23a7 7 0 01-.73-3.32z" />
            <path d="M291.84 573.48c0-2.52-.93-4.25-2.4-5.01.8.93 1.28 2.33 1.28 4.12v29.46c0 4.86-3.45 10.81-7.66 13.24l-21.12 12.18c-2.04 1.17-3.89 1.3-5.26.6 1.37 1.59 3.72 1.8 6.37.28l21.13-12.18c4.22-2.43 7.66-8.38 7.66-13.24v-29.45zM188.98 773.82l-2.58-4.1-15.38 9.24c-6.45 3.86-15.38-.62-15.38-.62l-150.1-86.3-1.28.73a8.49 8.49 0 00-3.82 6.62v27.18c0 1.22.44 2.07 1.15 2.47l147.94 85.62c1.35.76 6.84 3.7 11.1 3.6 4.87-.1 9.83-2.27 9.83-2.27l18.52-10.67c.1-.32-.12-31.19 0-31.5z" />
            <path d="M188.98 773.82l-2.58-4.1-15.38 9.24c-6.45 3.86-15.38-.62-15.38-.62l-150.1-86.3-1.28.73a8.49 8.49 0 00-3.82 6.62v27.18c0 1.22.44 2.07 1.15 2.47l147.94 85.62c1.35.76 6.84 3.7 11.1 3.6 4.87-.1 9.83-2.27 9.83-2.27l18.52-10.67c.1-.32-.12-31.19 0-31.5z" />
            <path d="M186.4 769.71l-15.38 9.25c-6.44 3.86-15.38-.62-15.38-.62l-150.1-86.3-1.27.73a8.16 8.16 0 00-3.06 3.56l153.77 88.74s8.94 4.48 15.38.63l18.62-11.88-2.57-4.1zM222.4 707.1l.36-.15-.31.33-2.53 2.7c-12.85 13.68-24.26 32.67-30.5 50.8l-3.01 8.93 2.57 4.11 3.38-12.04c6.1-17.65 17.26-36.29 29.84-49.68l2.52-2.7 73.37-42.28.05-3.74-75.74 43.72zM190.6 619.48c-2.4.14-6.55-1.93-6.55-1.93L30.61 527.26 5.8 608.27l152.38 88.38s7.35 3.45 12.04-.58c2.1-1.4 4.42-4.74 5.14-7.44l17.49-64.74c.73-2.7-.3-4.68-2.26-4.41z" />
            <path d="M189.68 626.55c.2-.72.18-1.21.13-1.46h-.34c-2.9 0-6.02-1.45-6.46-1.68l-.07-.04-.98-.54-153.04-90.06-22.4 73.2 152.74 88.6c.44.19 2.71 1.12 5.2 1.12 1.74 0 3.17-.47 4.25-1.4l.1-.1.14-.08c1.63-1.09 3.59-3.93 4.17-6.09l16.56-61.47z" />
            <path d="M189.68 626.55c.2-.72.18-1.21.13-1.46h-.34c-2.9 0-6.02-1.45-6.46-1.68l-.07-.04-.98-.54-153.04-90.06-22.4 73.2 152.74 88.6c.44.19 2.71 1.12 5.2 1.12 1.74 0 3.17-.47 4.25-1.4l.1-.1.14-.08c1.63-1.09 3.59-3.93 4.17-6.09l16.56-61.47zM99.31 772.05c0 1.39-.97 1.95-2.18 1.26L56.4 749.8a4.82 4.82 0 01-2.18-3.76v-7.48c0-1.39.97-1.95 2.18-1.26l40.74 23.49c1.2.7 2.18 2.39 2.18 3.77v7.48z" />
            <path d="M97.05 771.16l-40.58-23.4v-8.56l40.58 23.4z" />
            <path d="M172.7 812.4s-5.27 2.3-10.47 2.43c-4.53.1-10.38-3.03-11.8-3.84L.43 724.19v3.32c0 1.23.44 2.08 1.15 2.48l147.94 85.61c1.35.77 6.84 3.71 11.1 3.62 4.87-.12 9.83-2.28 9.83-2.28l18.52-10.68c.01-.06.02-1.3.02-3.25l-16.3 9.4zM245.6 675.3c0 1.22-.85 2.71-1.9 3.32l-7.88 4.54c-1.05.6-1.91.1-1.91-1.1v-6.48c0-1.22.85-2.7 1.91-3.31l7.87-4.55c1.06-.6 1.92-.1 1.92 1.1v6.49z" />
            <path d="M247.51 669.48c0 1.1-.86 2.5-1.9 3.11l-12.03 6.93c-1.06.61-1.92.2-1.92-.91 0-1.1.86-2.51 1.91-3.12l12.03-6.93c1.05-.6 1.91-.19 1.91.92zM180.22 772.16h1.17V690.7h-1.17zM184.22 702.21l.97 1.21a3.8 3.8 0 01-.43-.65 3.48 3.48 0 01-.54-.56zM184.77 702.77c.02 0 .03 0 .03-.03-.01 0-.03 0-.03.03z" />
            <path d="M298.08 624l-9.88 5.18-13.4 7.14-26.73 14.45-.07.04-.08.05-15.82 12.97-15.74 13.07-15.77 13.03-7.94 6.45-3.97 3.22-1.98 1.6-1 .82-.5.4-.25.2-.12.1c-.02 0-.03 0-.03.02.02 0 .04 0 .03-.02l.12-.1.26-.2.5-.4 1-.79 2-1.59 4.01-3.17 8.02-6.35 16.16-12.55 16.17-12.54 16.02-12.57 26.68-14.33 13.34-7.26 8.97-4.98v-1.88z" />
            <path d="M249.67 590.3c0-1.43-.9-3.06-2.03-3.73-1.14-.65-3-.61-4.24.1l-38.64 22.28.6 1.01 38.63-22.27a3.5 3.5 0 013.06-.1c.77.43 1.45 1.7 1.45 2.7v121.8l1.17-.67V590.29zM168.14 753.67c-.67-.7-1.74-.86-2.76-.37a9.9 9.9 0 01-3.54.8h-1.55c-1.01 0-2.66-.44-3.54-.94L133 739.46a2.83 2.83 0 00-1.43-.42c-1.31 0-2.23 1.04-2.23 2.53v13.87c0 1.64 1.07 3.5 2.5 4.33l23.76 13.7c1.22.7 3.3 1.25 4.7 1.25h1.55c1.6 0 3.52-.99 4.44-2.3l.18-.26a10.27 10.27 0 001.62-4.6l.7-11.84a2.71 2.71 0 00-.64-2.05zM5.54 687.54l28.45 15.95c.47.27.94.4 1.42.4.67 0 1.27-.28 1.68-.79.37-.45.56-1.06.56-1.75v-13.87a5.3 5.3 0 00-2.51-4.3l-29.6-16.6v20.96z" />
            <path d="M160.3 773.56c-1.22 0-3.07-.5-4.13-1.11l-23.76-13.7a4.25 4.25 0 01-1.92-3.3v-13.88c0-1.21.87-1.71 1.92-1.1l23.76 13.7c1.06.6 2.91 1.1 4.13 1.1h1.54c1.21 0 3.04-.42 4.05-.92 1.02-.5 1.8.08 1.73 1.3l-.7 11.83a9.12 9.12 0 01-1.41 4l-.19.27a4.8 4.8 0 01-3.48 1.8h-1.54zM5.54 686.2l29.02 16.27c1.06.6 1.93.1 1.93-1.12v-13.87c0-1.21-.87-2.7-1.93-3.29L5.54 667.91v18.29zM41 738.06c.5 1.35.05 1.96-1.02 1.35l-15.38-8.86a4.26 4.26 0 01-1.9-3.31v-5.53c0-1.21.85-1.7 1.9-1.1l10.8 6.22c1.05.6 2.3 2.2 2.8 3.57l2.8 7.66z" />
            <path d="M38.2 730.4a7.82 7.82 0 00-2.8-3.57l-8.47-4.89v4.09c0 1.2.86 2.7 1.9 3.3l11.38 6.56-2-5.5zM110.65 777.62c-.5.79-.03 1.93 1.02 2.53l15.38 8.87c1.05.6 1.9.11 1.9-1.1v-5.53c0-1.21-.85-2.7-1.9-3.31l-10.8-6.22c-1.04-.61-2.31-.47-2.8.32l-2.8 4.44z" />
            <path d="M127.05 779.08l-10.15-5.86-2.01 3.19c-.5.78-.04 1.92 1.01 2.53l13.06 7.53v-4.08c0-1.21-.86-2.7-1.9-3.31zM118.49 747.5c-.86 2.87-4.04 3.78-7.07 2.03l-59.23-34.14c-3.03-1.75-6.2-6.33-7.07-10.18l-4.69-15.53c-.86-3.85.93-5.57 3.96-3.82l74.83 43.15c3.03 1.74 4.82 5.52 3.96 8.38l-4.69 10.11z" />
            <path d="M40.34 689.1l82.93 47.82a6.6 6.6 0 00-.47-3.78L40.8 685.87c-.52.65-.7 1.76-.47 3.23zM42.01 694.91l79.6 45.9 1.28-2.78-82.17-47.37zM43.94 701.32l75.72 43.66 1.29-2.77-78.28-45.14zM44.44 702.98l.68 2.23c.15.66.39 1.35.66 2.04l72.05 41.54c.27-.37.51-.8.66-1.29l.67-1.44-74.72-43.08z" />
            <path d="M113.96 748.51l-59.22-34.13c-3.04-1.75-6.22-6.34-7.08-10.2l-4.7-15.51c-.31-1.44-.26-2.58.08-3.36-2.17-.56-3.33 1.14-2.61 4.37l4.69 15.53c.86 3.85 4.04 8.43 7.07 10.18l59.22 34.14c2.2 1.27 4.46 1.12 5.88-.13a6.05 6.05 0 01-3.33-.89z" />
            <path
              d="M145.59 757.43c0 4.4-3.1 6.18-6.91 3.98-3.81-2.2-6.91-7.55-6.91-11.95 0-4.4 3.1-6.18 6.9-3.99 3.83 2.2 6.92 7.56 6.92 11.96zM32.66 692.6c0 4.4-3.09 6.18-6.9 3.99-3.82-2.2-6.92-7.56-6.92-11.95 0-4.4 3.1-6.18 6.91-3.99 3.82 2.2 6.91 7.55 6.91 11.95z"
              stroke="#FFF"
            />
            <path
              d="M158.5 765.5c0 3.48-2.44 4.9-5.46 3.15-3.02-1.74-5.46-5.97-5.46-9.44 0-3.48 2.44-4.9 5.46-3.15 3.02 1.74 5.46 5.97 5.46 9.45z"
              stroke="#57EFFE"
              fill="#001428"
            />
            <path d="M148.96 796.36c0 2.89-2.03 4.06-4.52 2.61a9.98 9.98 0 01-4.54-7.83c0-2.88 2.04-4.05 4.54-2.6a10 10 0 014.52 7.82z" />
            <path d="M144.43 788.53l-.06-.02c-1.04.26-1.72 1.27-1.72 2.86a9.12 9.12 0 004.13 7.15c.43.25.84.4 1.23.47.58-.54.95-1.42.95-2.63a10 10 0 00-4.53-7.83zM15.96 722.14c0 2.89-2.02 4.05-4.52 2.6a9.98 9.98 0 01-4.54-7.82c0-2.88 2.04-4.05 4.54-2.61a10 10 0 014.52 7.83z" />
            <path d="M11.44 714.3c-.03 0-.04 0-.07-.02-1.04.27-1.72 1.28-1.72 2.87a9.14 9.14 0 004.14 7.15c.42.24.82.38 1.22.47.59-.54.95-1.43.95-2.63a10 10 0 00-4.52-7.83z" />
            <path
              d="M17.67 684.38c0 3.48-2.44 4.9-5.46 3.15-3.02-1.74-5.46-5.97-5.46-9.44 0-3.48 2.44-4.9 5.46-3.16 3.02 1.74 5.46 5.97 5.46 9.45z"
              stroke="#57EFFE"
              fill="#001428"
            />
            <path d="M165.9 754.35c-1.02.5-2.85.92-4.06.92h-1.26v18.29h1.26c1.21 0 2.78-.81 3.48-1.81l.19-.26c.7-1 1.33-2.8 1.4-4l.7-11.84c.08-1.22-.7-1.8-1.71-1.3zM195.42 714.91c0 .82-.58 1.83-1.3 2.24l-3.98 2.3c-.71.4-1.3.07-1.3-.75v-1.5c0-.83.59-1.83 1.3-2.24l3.98-2.3c.72-.41 1.3-.07 1.3.75v1.5z" />
            <path d="M194.92 714.27c0 .81-.58 1.83-1.29 2.23l-3.98 2.3c-.72.41-1.3.08-1.3-.75v-1.5c0-.83.58-1.83 1.3-2.24l3.98-2.3c.71-.4 1.29-.07 1.29.75v1.5zM167.62 716.38c-3.63 1.24-8.31-1-8.31-1l-9.75-5.7v30.38l8.43 4.94s3.9 3.55 9.63 1.77l3.63-2.09V714.3l-3.63 2.08z" />
            <path d="M167.62 718.05l3.63-2.1v-1.65l-3.63 2.08c-3.63 1.24-8.31-1-8.31-1l-9.75-5.7v1.66l9.75 5.7s4.68 2.25 8.3 1zM5.54 622.38v3l10.36 6.06v30.38l-13.77-8.05V626.7s.88-4.32 3.4-4.32z" />
            <path d="M2.13 652.32v1.45l13.77 8.05v-1.69z" />
            <path d="M5.54 625.37v-2.99c-.5 0-.93.17-1.3.44l.15 3.27 10.36 6.07v29l1.15.66v-30.38l-10.36-6.07zM15.9 631.44v29.53l133.66 77.94.57-28.9z" />
            <path d="M157.99 743.85l-8.43-4.94v1.15l8.43 4.94s3.9 3.55 9.63 1.77l3.63-2.09v-1.14l-3.63 2.08c-5.73 1.77-9.63-1.77-9.63-1.77zM15.9 636.82l134.14 77.34.05-2.43L15.9 634.37zM15.9 641.05l134.06 77.29.05-2.43-134.1-77.31zM15.9 645.27l133.98 77.24.04-2.41L15.9 642.83zM15.9 649.5l133.9 77.2.05-2.43L15.9 647.05zM15.9 653.73l133.82 77.15.04-2.43L15.9 651.28zM15.9 657.95l133.74 77.1.04-2.42L15.9 655.5z" />
            <path d="M149.6 736.81L15.9 659.73v1.24l133.66 77.94zM36.12 537l-7.82 41.86c-.98 5.28 1.59 6.94 5.73 3.67l33.95-26.78-31.86-18.74zM28.92 532.77l-22.1 72.25 4.25-2.82c2.1-1.39 4.4-4.73 5.13-7.42L32.4 534.8l-3.47-2.04zM91.6 569.65l-5.87 17.9 43.55 25.11 2.32-19.47zM131.6 593.2l-2.32 19.46 3.87-1.66 1.91-15.77z" />
            <path d="M129.28 612.66l-43.55-25.11-40.76 28.53 45.05 26.9zM179.2 621.2l-40.07-23.58-5.76 17.6 43.55 25.1z" />
            <path d="M182.66 623.22l-.7-.39-2.77-1.62-2.27 19.11 3.86-1.65zM176.92 640.32l-43.56-25.1-40.76 28.52 45.06 26.9zM218.07 663.22l8.18-6.51 6.58-55.29-23.52 13.26c-3.63 2.07-7.44 7.75-8.3 12.4l-.01.05-.02.06-5.5 23 22.6 13.03z" />
            <path d="M226.25 656.7l4.09-3.24 6.51-53.62h-1.2l-2.81 1.58zM181.45 642.1l-40.77 28.53 31.82 19a9 9 0 00.62-1.6l11.82-43.9-3.49-2.02zM195.49 650.2l-8.28 34.61a5.61 5.61 0 00-.06 2.52 5.7 5.7 0 002.05-1.15l28.87-22.96-22.58-13.02z" />
            <path
              d="M164.45 695.69c1.75 0 3.18-.47 4.26-1.4l.1-.1.14-.08c1.63-1.08 3.59-3.93 4.17-6.09l2.4-8.89L21.6 590.25 6.52 605.97l152.74 88.6c.43.19 2.71 1.12 5.2 1.12z"
              fill="#001428"
            />
            <path
              d="M57.86 585.09c10.1 0 22.55 8.18 27.79 18.27 5.24 10.1 1.3 18.29-8.81 18.29-10.12 0-22.56-8.2-27.8-18.29-5.24-10.09-1.3-18.27 8.82-18.27zM60.4 590c-7.39 0-10.27 5.98-6.44 13.36 3.83 7.38 12.92 13.36 20.31 13.36 7.4 0 10.28-5.98 6.44-13.36C76.9 595.98 67.8 590 60.41 590z"
              fill="#001428"
            />
            <path
              d="M29.9 595.02l4.8-4.79s14.2-1.84 34.34 10.46c20.13 12.3 21.98 22.63 21.98 22.63l-2.46 5.57s-22.04-3.7-40.52-13.42c-18.47-9.72-18.14-20.45-18.14-20.45z"
              fill="#001428"
            />
            <path d="M199.76 671.58l-7.27-4.19c-1.73-1-3.16-.18-3.16 1.83v24.36a7 7 0 003.16 5.48l7.26 4.19c1.75 1 3.17.18 3.17-1.83v-24.36a7 7 0 00-3.16-5.48z" />
            <path
              d="M199.76 671.58l-7.27-4.19c-1.73-1-3.16-.18-3.16 1.83v24.36a7 7 0 003.16 5.48l7.26 4.19c1.75 1 3.17.18 3.17-1.83v-24.36a7 7 0 00-3.16-5.48z"
              fill="#001428"
            />
            <path d="M199.76 671.58l-7.27-4.19c-1.29-.74-2.4-.48-2.9.53.36.04.76.17 1.17.4l7.26 4.19a7.03 7.03 0 013.17 5.48v24.36c0 .52-.1.95-.27 1.3 1.18.14 2-.7 2-2.23v-24.36a7 7 0 00-3.16-5.48zM181.85 677.03v6.45l7.48 2.28v-4.4z" />
            <path
              d="M269.76 529.6l-.43-.25c1.35 1.22 1.11 2.84-.71 3.9l-80.17 46.23a8.53 8.53 0 01-7.66 0l-2.06-1.2c.2.19.44.36.71.52l2.5 1.44a8.5 8.5 0 007.65 0L269.76 534c2.1-1.2 2.1-3.2 0-4.4z"
              strokeLinejoin="round"
            />
            <path
              d="M183.95 580.62l-2.5-1.44c-2.1-1.21-2.1-3.2 0-4.4l80.17-46.24c1.22-.7 2.9-.98 4.5-.87-2.07-.67-4.75-.52-6.5.5l-80.18 46.22c-2.1 1.2-2.1 3.2 0 4.4l2.5 1.45c.9.5 2 .8 3.17.87-.42-.13-.8-.28-1.16-.49zM155.29 459.58l-.43-.25c1.35 1.2 1.11 2.84-.72 3.9l-80.16 46.22a8.51 8.51 0 01-7.66 0l-2.07-1.2c.21.19.44.36.72.52l2.5 1.45a8.53 8.53 0 007.65 0l80.17-46.23c2.1-1.21 2.1-3.2 0-4.41z"
              strokeLinejoin="round"
            />
            <path
              d="M69.48 510.6l-2.5-1.45c-2.1-1.21-2.1-3.2 0-4.4l80.17-46.23a8.12 8.12 0 014.49-.88c-2.07-.67-4.74-.52-6.5.5l-80.17 46.22c-2.1 1.21-2.1 3.2 0 4.4l2.5 1.46c.89.5 2 .78 3.17.87-.42-.13-.81-.3-1.16-.5zM182.92 477.8l-.44-.24c1.36 1.2 1.12 2.84-.71 3.9l-80.17 46.22a8.52 8.52 0 01-7.65 0l-2.07-1.2c.21.2.44.37.71.53l2.5 1.43a8.49 8.49 0 007.66 0l80.17-46.22c2.1-1.21 2.1-3.2 0-4.41z"
              strokeLinejoin="round"
            />
            <path
              d="M97.1 528.83l-2.5-1.45c-2.1-1.21-2.1-3.2 0-4.4l80.18-46.23a8.1 8.1 0 014.49-.87c-2.07-.68-4.75-.53-6.5.49l-80.18 46.22c-2.1 1.21-2.1 3.2 0 4.41l2.5 1.44c.9.51 2.01.8 3.17.88-.4-.14-.8-.3-1.15-.5zM238.26 512.58l-.44-.25c1.35 1.2 1.12 2.84-.72 3.9l-80.16 46.22a8.51 8.51 0 01-7.65 0l-2.07-1.19c.2.18.44.35.7.51l2.51 1.45a8.48 8.48 0 007.66 0l80.17-46.23c2.1-1.21 2.1-3.2 0-4.41z"
              strokeLinejoin="round"
            />
            <path
              d="M152.44 563.6l-2.5-1.44c-2.1-1.22-2.1-3.2 0-4.41l80.16-46.23a8.2 8.2 0 014.5-.88c-2.07-.67-4.74-.52-6.5.5l-80.17 46.22c-2.1 1.22-2.1 3.2 0 4.41l2.5 1.45c.88.5 2 .79 3.16.87-.4-.13-.8-.3-1.15-.5zM211.11 494.58l-.43-.25c1.35 1.2 1.12 2.84-.71 3.9l-80.17 46.22a8.49 8.49 0 01-7.66 0l-2.06-1.19c.2.18.44.35.71.51l2.5 1.44a8.48 8.48 0 007.65 0l80.17-46.22c2.1-1.22 2.1-3.2 0-4.4z"
              strokeLinejoin="round"
            />
            <path
              d="M125.3 545.6l-2.5-1.44c-2.1-1.22-2.1-3.2 0-4.42l80.17-46.22c1.22-.7 2.9-.99 4.5-.88-2.07-.67-4.75-.51-6.5.5l-80.18 46.22c-2.1 1.21-2.1 3.2 0 4.41l2.5 1.44c.9.51 2.01.8 3.17.88-.41-.14-.8-.3-1.16-.5z"
              strokeLinejoin="round"
            />
            <clipPath id="open-truck-side">
              <use href="#open-truck-path" />
            </clipPath>
            <g clipPath="url(#open-truck-side)">
              <g transform="translate(220 480)">{children}</g>
            </g>

            <path
              clipPath="url(#open-truck-side)"
              d="M357.24 449.83l558.7-322v-2.37L357.23 447.7z"
            />
            <path d="M357.24 666.51l558.7-322V125.46l-558.7 322.23z" />
          </g>
        </g>
      </svg>
    );
  }
}

export class Palet extends Component {
  static propTypes = {
    innerClassName: PropTypes.string,
    children: PropTypes.node,
    transform: PropTypes.string,
    isCube: PropTypes.bool,
    x: PropTypes.number /* 0 -> 8 */,
    y: PropTypes.number /* 0 -> 2 */,
  };

  render() {
    const { innerClassName, transform, x, y, isCube, children } = this.props;
    let transformStyle = transform;

    if (
      transform === undefined &&
      x !== undefined &&
      +x >= 0 &&
      +x <= 8 &&
      y !== undefined &&
      +y >= 0 &&
      +y <= 2
    ) {
      let newX = 74.5 + (+x - +y) * 61.5;
      let newY = 106 + (+x + +y) * -35.5;
      transformStyle = `translate(${newX} ${newY})`;
    }

    const threeByThreeBoxes = (
      <React.Fragment>
        {[0, 1, 2].map(z =>
          [2, 1, 0].map(y =>
            [2, 1, 0].map(x => <LittleBox key={`${x} ${y} ${z}`} x={x} y={y} z={z} />)
          )
        )}
      </React.Fragment>
    );

    return (
      <svg
        width="123"
        height="80"
        xmlns="http://www.w3.org/2000/svg"
        style={{ overflow: "visible" }}>
        <g transform={transformStyle}>
          <g className={innerClassName}>
            <g
              fillRule="evenodd"
              fill="#001428"
              stroke="#288CFF"
              strokeWidth=".89"
              strokeLinejoin="round">
              <path d="M6.09 40.66L1 37.72 61.19 2.97l5.09 2.94z" />
              <path d="M62.36 73.15l-5.08-2.94 60.18-34.75 5.09 2.94z" />
              <path d="M34.22 56.9l-5.08-2.94 60.19-34.75 5.08 2.94z" />
              <path d="M6.09 46.97v-6.31L66.28 5.9v6.31z" />
              <path d="M62.36 79.46l-5.08-2.93V70.2l5.08 2.94z" />
              <path d="M62.36 79.46v-6.31l60.19-34.75v6.31z" />
              <path d="M34.22 63.22V56.9l60.2-34.75v6.32z" />
              <path d="M71.28 66.03l-8.92 5.15L1 35.75l8.92-5.15z" />
              <path d="M1 35.75v1.97l61.36 35.43v-1.97z" />
              <path d="M62.36 71.18v1.97L71.28 68v-1.97z" />
              <path d="M84.1 58.63l-8.92 5.15-61.36-35.43 8.92-5.15z" />
              <path d="M13.82 28.35v1.97l61.36 35.43v-1.97z" />
              <path d="M75.18 63.78v1.97l8.92-5.15v-1.97z" />
              <path d="M96.91 51.23L88 56.38 26.64 20.95l8.91-5.15z" />
              <path d="M26.64 20.95v1.97L88 58.35v-1.97z" />
              <path d="M88 56.38v1.97l8.91-5.15v-1.97z" />
              <path d="M109.73 43.83l-8.92 5.15-61.36-35.43 8.92-5.15z" />
              <path d="M39.45 13.55v1.97l61.36 35.43v-1.97z" />
              <path d="M100.81 48.98v1.97l8.92-5.15v-1.97z" />
              <path d="M122.55 36.43l-8.92 5.15L52.27 6.15 61.2 1z" />
              <path d="M52.27 6.15v1.97l61.36 35.43v-1.97z" />
              <path d="M113.63 41.58v1.97l8.92-5.15v-1.97z" />
              <path d="M1 37.72v6.32l5.09 2.93v-6.31z" />
              <path d="M29.14 53.97v6.31l5.08 2.94V56.9z" />
            </g>

            {isCube && threeByThreeBoxes}
            {children}
          </g>
        </g>
      </svg>
    );
  }
}

export class LittleBox extends Component {
  static propTypes = {
    transform: PropTypes.string,
    color: PropTypes.string,
    x: PropTypes.number /* 0 -> 2 */,
    y: PropTypes.number /* 0 -> 2 */,
    z: PropTypes.number /* 0 -> 3 */,
  };

  render() {
    const { color, transform, x, y, z } = this.props;
    let transformStyle = transform;

    if (
      transform === undefined &&
      x !== undefined &&
      +x >= 0 &&
      +x <= 2 &&
      y !== undefined &&
      +y >= 0 &&
      +y <= 2 &&
      z !== undefined &&
      +z >= 0 &&
      +z <= 3
    ) {
      let newX = 40.36 + (+x - +y) * 20.05;
      let newY = 27.0 + (+x + +y) * -11.77 + +z * -19.35;
      transformStyle = `translate(${newX} ${newY})`;
    }

    return (
      <svg
        width="41"
        height="45"
        xmlns="http://www.w3.org/2000/svg"
        style={{ overflow: "visible" }}>
        <g
          transform={transformStyle}
          fill="#001428"
          stroke={color || "#6482A4"}
          strokeWidth=".9"
          strokeLinejoin="round"
          fillRule="evenodd">
          <path d="M40.4 12.8v19.4L20.2 44V24.6z" />
          <path d="M.5 12.8l19.8 11.7v19.4L.5 32.2z" />
          <path d="M20.5 24.5L.5 12.7 20.2 1.2l20.2 11.6z" />
        </g>
      </svg>
    );
  }
}

export class BigBox extends Component {
  static propTypes = {
    transform: PropTypes.string,
    color: PropTypes.string,
    x: PropTypes.number /* 0 -> 1 */,
    y: PropTypes.number /* 0 -> 1 */,
    z: PropTypes.number /* 0 -> 1 */,
  };

  render() {
    const { color, transform, x, y, z } = this.props;
    let transformStyle = transform;

    if (
      transform === undefined &&
      x !== undefined &&
      +x >= 0 &&
      +x <= 1 &&
      y !== undefined &&
      +y >= 0 &&
      +y <= 1 &&
      z !== undefined &&
      +z >= 0 &&
      +z <= 1
    ) {
      let newX = 31 + (+x - +y) * 31;
      let newY = 6 + (+x + +y) * -18 + +z * -29.5;
      transformStyle = `translate(${newX} ${newY})`;
    }

    return (
      <svg
        width="63"
        height="68"
        xmlns="http://www.w3.org/2000/svg"
        style={{ overflow: "visible" }}>
        <g
          transform={transformStyle}
          fill="#001428"
          stroke={color || "#288CFF"}
          strokeWidth=".89"
          strokeLinejoin="round"
          fillRule="evenodd">
          <path d="M31.5 36.76L.56 18.43 31.03.54 62.32 18.6z" />
          <path d="M62.32 18.65v30.07l-31.25 18.2V36.83z" />
          <path d="M.46 18.6l30.8 18.1v30.07L.46 48.67z" />
        </g>
      </svg>
    );
  }
}
